import { styled, Container } from '@material-ui/core';
import * as Yup from 'yup';
import {
  CybersourceFormikBillingValues,
  CybersourceFormikInitialTermsOnlyValues,
  FormikBillingValues,
} from 'models/Billing';
import { ZipRegex } from 'utils/validationUtils';
import StripeContainer from 'components/StripeContainer';
import { CybersourceProvider } from 'contexts/CybersourceContext';
import StripePaymentPage from './StripePaymentPage';
import CybersourcePaymentPage from './CybersourcePaymentPage';
import { useTransactionDetails } from 'hooks/useTransactionDetails';
import CybersourceContainer from 'components/CybersourceContainer';
const psl = require('psl');

export const PageContainer = styled(Container)(({ theme }) => ({
  display: 'grid',
  gap: '10px',
  color: '#002447',
  gridTemplateColumns: '2fr 1fr',
  gridTemplateRows: '150px auto 1fr',
  gridTemplateAreas: `
    'stepper stepper'
    'form sidebar'
  `,
  // Breakpoint which triggers on smaller screens
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    gridTemplateRows: '90px auto',
    gridTemplateAreas: `
      'stepper'
      'form'
      'sidebar'
    `,
  },
}));

export const initialValues: FormikBillingValues = {
  cardholderName: '',
  billingAddress: '',
  billingAddress2: '',
  country: 'US',
  region: '',
  city: '',
  zip: '',
  vatId: '',
  acceptedTerms: false,
};

export const cybersourceInitialValues: CybersourceFormikBillingValues = {
  cardType: [{ name: '' }],
  firstName: '',
  lastName: '',
  email: '',
  accountFirstName: '',
  accountLastName: '',
  accountEmail: '',
  company: '',
  phoneNumber: '',
  ...initialValues,
};

export const initialTermsOnlyValues: CybersourceFormikInitialTermsOnlyValues = {
  acceptedTerms: false,
};

const defaultValidationObject = {
  billingAddress: Yup.string().required(`Billing address is required`),
  region: Yup.string().required(`State / Region is required`),
  city: Yup.string().required(`City is required`),
  zip: Yup.string().when('country', {
    is: 'US',
    then: Yup.string()
      .matches(ZipRegex, `Zip / Postal code must be 5 digits`)
      .required(`Zip / Postal code is required`),
    otherwise: Yup.string().required(`Zip / Postal code is required`),
  }),
  vatId: Yup.string(),
  acceptedTerms: Yup.boolean().required('Required').oneOf([true], 'Agreement is required'),
};

export const validationSchema = Yup.object({
  cardholderName: Yup.string()
    .required(`Name on card is required`)
    .min(2, `Name on card must be greater than 2 characters`),
  ...defaultValidationObject,
});

export const cybersourceValidationSchema = Yup.object({
  firstName: Yup.string().required(`First name is required`),
  lastName: Yup.string().required(`Last name is required`),
  email: Yup.string()
    .email('Please enter a valid email')
    .test('is-valid-publicsuffix', 'Please enter a valid email', (value) => {
      const isValid =
        value !== null && typeof value === 'string' && value.includes('@') && psl.isValid(value.split('@')[1]);
      return isValid;
    })
    .required('Email is required'),
  ...defaultValidationObject,
});

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const nameRegExp = /^[a-zA-Z0-9 ]*$/;
export const cybersourceSelfServeValidationSchema = Yup.object({
  accountFirstName: Yup.string()
    .matches(nameRegExp, `First name may only be letters and numbers`)
    .required('First Name is required'),
  accountLastName: Yup.string()
    .matches(nameRegExp, `Last name may only be letters and numbers`)
    .required('Last Name is required'),
  accountEmail: Yup.string()
    .email('Please enter a valid email')
    .test('is-valid-publicsuffix', 'Please enter a valid email', (value) => {
      const isValid =
        value !== null && typeof value === 'string' && value.includes('@') && psl.isValid(value.split('@')[1]);
      return isValid;
    })
    .required('Email is required'),
  phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
  company: Yup.string().matches(nameRegExp, `Company name may only be letters and numbers`),
  firstName: Yup.string().required(`First name is required`),
  lastName: Yup.string().required(`Last name is required`),
  email: Yup.string()
    .email('Please enter a valid email')
    .test('is-valid-publicsuffix', 'Please enter a valid email', (value) => {
      const isValid =
        value !== null && typeof value === 'string' && value.includes('@') && psl.isValid(value.split('@')[1]);
      return isValid;
    })
    .required('Email is required'),
  ...defaultValidationObject,
});

export const termsOnlyValidationSchema = Yup.object({
  acceptedTerms: Yup.boolean().required('Required').oneOf([true], 'Agreement is required'),
});

const SignupPaymentPage = () => {
  const { transactionDetails } = useTransactionDetails();

  if (!transactionDetails) {
    return null;
  }

  return transactionDetails?.payment_gateway === 'stripe' ? (
    <StripeContainer>
      <StripePaymentPage portalConfirmationPageUrl={transactionDetails.redirect_url} />
    </StripeContainer>
  ) : (
    <CybersourceContainer>
      <CybersourceProvider>
        <CybersourcePaymentPage portalConfirmationPageUrl={transactionDetails.redirect_url} />
      </CybersourceProvider>
    </CybersourceContainer>
  );
};

export default SignupPaymentPage;
