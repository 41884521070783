import { useEffect, useState } from 'react';
import { styled, Typography } from '@material-ui/core';
import { Form, useFormikContext } from 'formik';
import FormTextField from './FormTextField';
import FormCardField from './FormCardField';
import CountrySelector from './CountrySelector';
import RegionSelector from './RegionSelector';
import VatField from './VatField';
import ReloadModal from './ReloadModal';
import { useCountries } from 'hooks/useCountries';
import { useRegions } from 'hooks/useRegions';
import { FormikBillingValues } from 'models/Billing';
import CybersourceCardDetails from 'components/CybersourceCardDetails/CybersourceCardDetails';
import { useTransactionDetails } from 'hooks/useTransactionDetails';

const StyledForm = styled(Form)(({ theme }) => ({
  // Breakpoint which triggers on smaller screens
  [theme.breakpoints.down('sm')]: {
    marginTop: '10px',
  },
  borderRadius: '16px',
}));

const StyledAccountHeader = styled(Typography)(() => ({
  marginBottom: '40px',
  marginTop: '40px',
  borderRadius: '16px',
}));

const StyledBillingHeader = styled(Typography)(() => ({
  marginBottom: '40px',
  marginTop: '40px',
  borderRadius: '16px',
}));

interface BillingFormProps {
  cybersource?: boolean;
}

const BillingForm = ({ cybersource }: BillingFormProps) => {
  const { countries } = useCountries();
  const { values } = useFormikContext<FormikBillingValues>();
  const { regions } = useRegions(values.country);
  const { transactionDetails } = useTransactionDetails();
  const isEsmSelfServe = transactionDetails?.signup_code?.includes('esm_self_serve');
  const accountDetailsAreNotPrepopulated = !(transactionDetails?.account_details_prepopulated === true);
  const [ReloadModalOpen, setReloadModalOpen] = useState(false);

  const handleReloadModalClose = () => {
    setReloadModalOpen(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setReloadModalOpen(true);
    }, 840000);
  }, []);

  return (
    <>
      <StyledForm id="billingForm">
        {isEsmSelfServe && accountDetailsAreNotPrepopulated ? (
          <>
            <StyledAccountHeader variant="h3">Account Details</StyledAccountHeader>
            <FormTextField label="Account First name" name="accountFirstName" maxChars={256} />
            <FormTextField label="Account Last name" name="accountLastName" maxChars={64} />
            <FormTextField label="Account Email" name="accountEmail" maxChars={64} />
            <FormTextField label="Company (optional)" name="company" maxChars={64} />
            <FormTextField label="Phone Number (optional)" name="phoneNumber" maxChars={64} />
          </>
        ) : (
          false
        )}
        <StyledBillingHeader variant="h3">Payment Details</StyledBillingHeader>
        {cybersource ? (
          <>
            <CybersourceCardDetails />
            <FormTextField label="First name" name="firstName" maxChars={256} />
            <FormTextField label="Last name" name="lastName" maxChars={64} />
            <FormTextField label="Email" name="email" maxChars={64} />
          </>
        ) : (
          <>
            <FormTextField label="Name on card" name="cardholderName" maxChars={30} />
            <FormCardField label="Credit card" name="creditCard" />
          </>
        )}
        <FormTextField label="Billing address" name="billingAddress" maxChars={256} />
        <FormTextField label="Billing address 2 (optional)" name="billingAddress2" maxChars={64} />
        <CountrySelector countries={countries} />
        <RegionSelector regions={regions} />
        <FormTextField label="City" name="city" maxChars={64} />
        <FormTextField label="Zip / Postal code" name="zip" maxChars={64} />
        <VatField country={values.country} />
      </StyledForm>
      <ReloadModal open={ReloadModalOpen} handleClose={handleReloadModalClose} />
    </>
  );
};

export default BillingForm;
